$c-primary: #00a7e1;
$c-secandary: #00a7e1;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Effra', sans-serif;
$font-family-heading: 'Effra', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  position: relative;
  width: 100%;

  .inputWrapper {
    position: relative;
    height: 5rem; //80

    .icon {
      position: absolute;
      right: 0.625rem;
      top: 50%;
      margin-top: -0.7rem;
    }

    .manual_cta {
      display: block;
      text-align: right;
      font-size: 0.875rem; //14
      margin-bottom: 1.25rem; //20
      a {
        text-decoration: none;
        color: var(--secondary);
      }
    }

    .validatingAddress {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 40%;
      font-style: italic;
    }

    .inputElement {
      input {
        height: 5rem; //80
        padding: 0.7rem 3rem 0.7rem 0.7rem;
      }

      textarea {
        height: 5rem; //80
        padding: 0.7rem 2rem 0.7rem 40%;
      }
    }
  }

  .title {
    font-size: 0.875rem; //14
    margin-bottom: 1.25rem; //20
  }

  .input {
    margin-bottom: 1.875rem; //30
    &.halfWith {
      max-width: 50%;
      margin-right: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .addresses {
    display: block;
    background: #fff;
    padding: 0;
    position: absolute;
    width: calc(100%);
    max-height: 13.75rem; //220
    overflow: auto;
    margin-top: -1.25rem; //20
    z-index: 2;

    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.18);

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 3px;

      padding: 0.7rem 0.8125rem; //18 13
      border-bottom: 1px solid #f2f2f2;
      //color: var(--primary);
      font-size: 0.875rem; //14
      line-height: 1.125rem; //18

      .grow {
        flex-grow: 1;
      }

      &:last-child {
        border-bottom: 0;
      }

      &.loading {
        text-align: center;
      }

      &.error {
        color: $c-danger;
        font-size: 0.8125rem;
      }
    }
  }

  .detailedWrapper {
    padding-top: 3.375rem; //54
    .inputGroup {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      > * {
        flex: 0 0 49%;
      }
    }

    .countries {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      padding-top: 40px;
      margin-bottom: 1.875rem;
    }
  }
}
