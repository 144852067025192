$c-primary: #00a7e1;
$c-secandary: #00a7e1;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Effra', sans-serif;
$font-family-heading: 'Effra', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$primary: var(--primary);
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }
  .container {
    max-width: calc(100vw - 3rem);
    .userDetailRow {
      position: relative;
      & > label {
        &:first-child {
          position: absolute;
          left: 0;
          top: 0;
          width: 35%;
          font-weight: bold;
          font-size: 1rem; //16
          line-height: 1.2;
          z-index: 1;
          height: 3.75rem; //60
          display: flex;
          align-items: center;
          word-break: break-word;
        }
      }
      .address-label-span {
        height: 5rem !important; //80
      }
    }
    .check-confirm {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }

  .confirm-name-empty {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $c-danger;
  }

  .error {
    color: $c-danger;
    font-size: 0.75rem; //12
  }

  .input {
    .halfWith {
      max-width: 50%;
      margin-right: 1rem !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
