$c-primary: #00a7e1;
$c-secandary: #00a7e1;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Effra', sans-serif;
$font-family-heading: 'Effra', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.inputWrapper {
  position: relative;
  width: 100%;

  textarea {
    resize: none;
    height: 3.75rem; //60
    padding-top: 1rem;
  }

  .input {
    display: flex;
    align-items: center;
  }

  .error {
    color: $c-danger;
    font-size: 0.8125rem; //13
  }

  input,
  .input,
  textarea {
    width: 100%;
    height: 3.75rem; //60
    font-size: 1rem; //16
    line-height: 1.2;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.7rem 3rem 0.7rem 0.7rem;
    color: #394448;
    transition: 0.1s ease-out;
    font-family: 'Rubik', sans-serif;
    margin: 0;
    &:focus {
      border-color: black;
    }
  }
}

.leftPadding30Perc {
  input,
  .input,
  textarea {
    padding: 0.5rem 0.7rem 0.5rem 40% !important;
  }
}

.search {
  &::after {
    background: url(_img('icons/png/search.png')) no-repeat 0 0;
  }
}
